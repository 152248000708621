export const DEFAULT_DASHBOARD_LAYOUT: ReactGridLayout.Layouts = {
  lg: [
    { i: "dailyCalls", x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
    { i: "callsByDirection", x: 0, y: 4, w: 6, h: 4, minW: 4, minH: 4 },
    { i: "callsByType", x: 6, y: 4, w: 6, h: 4, minW: 4, minH: 4 },
    {
      i: "totalCalls",
      x: 0,
      y: 8,
      w: 3,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "talkTime",
      x: 3,
      y: 8,
      w: 3,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "averageDuration",
      x: 6,
      y: 8,
      w: 3,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "busiestDay",
      x: 9,
      y: 8,
      w: 3,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
  ],
  md: [
    { i: "dailyCalls", x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
    { i: "callsByDirection", x: 0, y: 4, w: 6, h: 4, minW: 6, minH: 4 },
    { i: "callsByType", x: 6, y: 4, w: 6, h: 4, minW: 6, minH: 4 },
    {
      i: "totalCalls",
      x: 0,
      y: 8,
      w: 6,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "talkTime",
      x: 6,
      y: 8,
      w: 6,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "averageDuration",
      x: 0,
      y: 10,
      w: 6,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
    {
      i: "busiestDay",
      x: 6,
      y: 10,
      w: 6,
      h: 2,
      minW: 3,
      minH: 2,
      maxH: 2,
    },
  ],
  sm: [
    { i: "dailyCalls", x: 0, y: 0, w: 12, h: 4, minW: 12, minH: 4 },
    {
      i: "callsByDirection",
      x: 0,
      y: 4,
      w: 12,
      h: 4,
      minW: 12,
      minH: 4,
    },
    { i: "callsByType", x: 0, y: 8, w: 12, h: 4, minW: 12, minH: 4 },
    {
      i: "totalCalls",
      x: 0,
      y: 12,
      w: 12,
      h: 2,
      minW: 6,
      minH: 2,
      maxH: 3,
    },
    {
      i: "talkTime",
      x: 0,
      y: 14,
      w: 12,
      h: 2,
      minW: 6,
      minH: 2,
      maxH: 3,
    },
    {
      i: "averageDuration",
      x: 0,
      y: 16,
      w: 12,
      h: 2,
      minW: 6,
      minH: 2,
      maxH: 3,
    },
    {
      i: "busiestDay",
      x: 0,
      y: 18,
      w: 12,
      h: 2,
      minW: 6,
      minH: 2,
      maxH: 3,
    },
  ],
};
