"use client";

import "../../utils/common/charts";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./page.css"; // Import the CSS file for custom styles

import clsx from "clsx";
import { useRouter } from "next/navigation";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import CalendarCheckDynamic from "@/components/calendar/CalendarCheckDynamic";
import Container from "@/components/common/container";
import Button from "@/components/library/Button";
import { ICON_SIZES, Icon } from "@/components/library/Icon";
import StatCompCallsByDirection from "@/components/stats/StatCompCallsByDirection";
import StatCompCallsByType from "@/components/stats/StatCompCallsByType";
import StatCompDailyCalls from "@/components/stats/StatCompDailyCalls";
import StatCompDirectionCard from "@/components/stats/StatCompDirectionCard";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { useProfile } from "@/hooks/useProfile";
import {
  CallByDayStat,
  CallsByTypeStat,
  StatsResponse,
  TotalCallStat,
} from "@/types/admin/dashboard";
import { apiLive } from "@/utils/common/api";
import {
  FormatManHours,
  FormatNumber,
  FormatSeconds,
} from "@/utils/common/generic";
import { GetLoginLocation } from "@/utils/common/login";
import { DEFAULT_DASHBOARD_LAYOUT } from "@/utils/dashboard";

// Or another icon of your choice

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function Home() {
  const router = useRouter();
  const { user, getToken } = useAuthContext();
  const { org, ui } = useProfile();
  const { t } = useTranslation("stats");
  const { t: ct } = useTranslation("common");

  const [layout, setLayout] = useState(DEFAULT_DASHBOARD_LAYOUT);
  const [callsByDayStatus, setCallsByDayStatus] = useState<
    "loading" | "error" | "empty" | "success"
  >("loading");
  const [callsByDay, setCallsByDay] = useState<
    CallByDayStat[] | null | undefined
  >(undefined);
  const [totalCallsStatus, setTotalCallsStatus] = useState<
    "loading" | "error" | "empty" | "success"
  >("loading");
  const [totalCalls, setTotalCalls] = useState<
    TotalCallStat[] | null | undefined
  >(undefined);
  const [callsByTypeStatus, setCallsByTypeStatus] = useState<
    "loading" | "error" | "empty" | "success"
  >("loading");
  const [callsByType, setCallsByType] = useState<
    CallsByTypeStat[] | null | undefined
  >(undefined);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  useEffect(() => {
    const layoutString = window.localStorage.getItem("layout");
    if (layoutString) {
      setLayout(JSON.parse(layoutString));
    }
  }, []);

  useEffect(() => {
    const location = GetLoginLocation();

    if (location) {
      router.push(location);
      return;
    }
  }, [router, user]);

  const fetchCallsByDay = async () => {
    const statsResponse = await apiLive<StatsResponse<CallByDayStat[]>>(
      getToken,
      `/api/stats/450`
    );

    if (!statsResponse || !statsResponse.success) {
      setCallsByDayStatus("error");
      setCallsByDay(null);
      return;
    }

    if (statsResponse.stats.length === 0) {
      setCallsByDayStatus("empty");
      setCallsByDay(statsResponse.stats);
      return;
    }

    setCallsByDayStatus("success");
    setCallsByDay(statsResponse.stats);
  };

  const fetchTotalCalls = async () => {
    const statsResponse = await apiLive<StatsResponse<TotalCallStat[]>>(
      getToken,
      `/api/stats/449`
    );

    if (!statsResponse || !statsResponse.success) {
      setTotalCallsStatus("error");
      setTotalCalls(null);
      return;
    }

    if (statsResponse.stats.length === 0) {
      setTotalCallsStatus("empty");
      setTotalCalls(statsResponse.stats);
      return;
    }

    setTotalCallsStatus("success");
    setTotalCalls(statsResponse.stats);
  };

  const fetchCallsByType = async () => {
    const statsResponse = await apiLive<StatsResponse<CallsByTypeStat[]>>(
      getToken,
      `/api/stats/451`
    );

    if (!statsResponse || !statsResponse.success) {
      setCallsByTypeStatus("error");
      setCallsByType(null);
      return;
    }

    if (statsResponse.stats.length === 0) {
      setCallsByTypeStatus("empty");
      setCallsByType(statsResponse.stats);
      return;
    }

    setCallsByTypeStatus("success");
    setCallsByType(statsResponse.stats);
  };

  useEffect(() => {
    document.title = `GAIL | ${t("dashboard")}`;

    fetchCallsByDay();
    fetchTotalCalls();
    fetchCallsByType();

    posthog.onFeatureFlags(function () {
      setShowCalendar(!!posthog.isFeatureEnabled("ui-appointment-scheduling"));
    });
  }, []);

  const getTotalCallStat = (direction: string, value: "count" | "duration") => {
    const data: number =
      totalCalls?.find((tc) => tc.direction === direction)?.[value] ?? -1;

    if (value === "count") {
      return FormatNumber(data, ct, 2, false);
    } else {
      return FormatManHours(data, org.localization, ct, false);
    }
  };

  const getAverageDurationStat = (direction: string) => {
    const duration: number =
      totalCalls?.find((tc) => tc.direction === direction)?.duration ?? -1;
    const callCount: number =
      totalCalls?.find((tc) => tc.direction === direction)?.count ?? -1;

    if (duration > 0 && callCount > 0) {
      return FormatSeconds(duration / callCount, ct);
    }

    return "0";
  };

  const getBusiestDay = (direction: "inbound" | "outbound") => {
    const SevenDaysAgo = new Date();

    SevenDaysAgo.setDate(SevenDaysAgo.getDate() - 7);

    const busiestDay = callsByDay
      ?.filter(
        (cbd) =>
          cbd.direction === direction &&
          cbd.created_at > SevenDaysAgo.toISOString()
      )
      .sort((a, b) => b.count - a.count)[0];

    return busiestDay
      ? new Date(busiestDay.created_at.replace("Z", "")).toLocaleDateString(
          org.localization,
          {
            weekday: "long",
          }
        )
      : "0";
  };

  return (
    <Container
      title={t("dashboard")}
      subTitle={
        !user
          ? t("dashboardSubtitleGeneric")
          : t("dashboardSubtitle", { name: user.name })
      }
    >
      <>{showCalendar && <CalendarCheckDynamic />}</>
      <>
        {callsByDayStatus === "empty" &&
          totalCallsStatus === "empty" &&
          callsByTypeStatus === "empty" && (
            <div className={clsx("p-4", "bg-panel", "rounded-xl", "shadow-md")}>
              <h1>{t("noDataYet")}</h1>
              <hr className="my-2" />
              <p className={clsx("text-sm", "text-600")}>
                {t("noDataYetDescription")}
              </p>
              <p className={clsx("text-sm", "text-600", "mt-2")}>
                {t("noDataYetNextSteps")}
              </p>
              <p className={clsx("text-sm", "text-600", "mt-2")}>
                {t("noDataYetNextStepsCampaign")}
              </p>
              <div
                className={clsx(
                  "grid",
                  "gap-4",
                  "mt-4",
                  "grid-cols-1",
                  "md:grid-cols-2"
                )}
              >
                <Button
                  type="primary"
                  href={
                    ui.isUsingGalactus
                      ? "/receptionist/handbook-new"
                      : "/receptionist/handbook"
                  }
                >
                  {t("goToHandbook")}
                </Button>
                <Button
                  type="primary"
                  href={
                    ui.isUsingGalactus ? "/outreach/campaigns" : "/campaigns"
                  }
                >
                  {t("goToCampaigns")}
                </Button>
              </div>
            </div>
          )}
      </>
      <>
        {(callsByDayStatus !== "empty" ||
          totalCallsStatus !== "empty" ||
          callsByTypeStatus !== "empty") && (
          <>
            <ResponsiveGridLayout
              className="layout"
              layouts={layout}
              breakpoints={{ lg: 800, md: 525, sm: 0 }}
              cols={{ lg: 12, md: 12, sm: 12 }}
              rowHeight={100}
              draggableHandle=".drag-handle"
              margin={[10, 10]}
              containerPadding={[0, 0]}
              onLayoutChange={(layout, layouts) => {
                window.localStorage.setItem("layout", JSON.stringify(layouts));
                setLayout(layouts);
              }}
            >
              <div key="dailyCalls" className={clsx("grid-item")}>
                <div className="grid-content">
                  <StatCompDailyCalls callsByDay={callsByDay} />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="callsByDirection" className="grid-item">
                <div className="grid-content">
                  <StatCompCallsByDirection
                    isAdmin={false}
                    totalCalls={totalCalls}
                  />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="callsByType" className="grid-item">
                <div className="grid-content">
                  <StatCompCallsByType callsByType={callsByType} />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="totalCalls" className="grid-item">
                <div className="grid-content">
                  <StatCompDirectionCard
                    title={t("totalCalls")}
                    stats={
                      totalCalls
                        ? {
                            inbound: getTotalCallStat("inbound", "count"),
                            outbound: getTotalCallStat("outbound", "count"),
                          }
                        : totalCalls
                    }
                  />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="talkTime" className="grid-item">
                <div className="grid-content">
                  <StatCompDirectionCard
                    title={t("talkTime")}
                    stats={
                      totalCalls
                        ? {
                            inbound: getTotalCallStat("inbound", "duration"),
                            outbound: getTotalCallStat("outbound", "duration"),
                          }
                        : totalCalls
                    }
                  />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="averageDuration" className="grid-item">
                <div className="grid-content">
                  <StatCompDirectionCard
                    title={t("averageDuration")}
                    stats={
                      totalCalls
                        ? {
                            inbound: getAverageDurationStat("inbound"),
                            outbound: getAverageDurationStat("outbound"),
                          }
                        : totalCalls
                    }
                  />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
              <div key="busiestDay" className="grid-item">
                <div className="grid-content">
                  <StatCompDirectionCard
                    title={t("busiestDayThisWeek")}
                    stats={
                      callsByDay
                        ? {
                            inbound: getBusiestDay("inbound"),
                            outbound: getBusiestDay("outbound"),
                          }
                        : callsByDay
                    }
                  />
                </div>
                <div className="drag-handle">
                  <Icon icon="grip" size={ICON_SIZES.LG} />
                </div>
              </div>
            </ResponsiveGridLayout>
            <Button
              type="secondary"
              className="mt-4 mb-8"
              onClick={() => {
                window.localStorage.setItem(
                  "layout",
                  JSON.stringify(DEFAULT_DASHBOARD_LAYOUT)
                );
                setLayout(DEFAULT_DASHBOARD_LAYOUT);
              }}
            >
              {t("resetDashboard")}
            </Button>
          </>
        )}
      </>
    </Container>
  );
}
